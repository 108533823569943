import { all } from "redux-saga/effects";

import { authSaga } from "./auth/sagas";
import { commonSaga } from "./common/sagas";
import { customerSaga } from "./customer/sagas";
import { quoteSaga } from "./quote/sagas";
import { invoiceSaga } from "./invoice/sagas";
import { paymentSaga } from "./payment/sagas";
import { companySaga } from "./company/sagas";

export default function* rootSaga() {
  yield all([authSaga(), commonSaga(), customerSaga(), quoteSaga(), invoiceSaga(), paymentSaga(), companySaga()]);
}
