import { put, takeLatest, all } from "redux-saga/effects";

import { axiosClient } from "../../utils/helpers";
import { checkAuthorizationSuccess, checkAuthorizationFailure } from "./slice";
import { updateClientToken } from "../../utils/helpers/axios";

function* checkAuthorization({ payload }) {
  try {
    let { data } = yield axiosClient.post(`/auth/login`, payload);
    updateClientToken(data?.access_token);
    yield put(checkAuthorizationSuccess(data.access_token));
  } catch (error) {
    yield put(checkAuthorizationFailure());
  }
}

export function* authSaga() {
  yield all([takeLatest("auth/checkAuthorization", checkAuthorization)]);
}
