import Auth from "./auth/slice";
import Common from "./common/slice";
import Customer from "./customer/slice";
import Quote from "./quote/slice";
import Invoice from "./invoice/slice";
import Payment from "./payment/slice";
import Company from "./company/slice";

const rootReducers = {
  Auth,
  Common,
  Customer,
  Quote,
  Invoice,
  Payment,
  Company,
};

export default rootReducers;
