import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import RequireAuth from "./RequireAuth";
import UnauthorizedRoute from "./UnauthorizedRoute";

import { NoMatch, ServerError, UnAuthorized } from "../component/app/NoMatch";

const CustomerLayout = React.lazy(() => import("../component/customer"));
const Customer = React.lazy(() => import("../component/customer/Customer"));
const CreateCustomer = React.lazy(() => import("../component/customer/CreateCustomer"));
const ViewCustomer = React.lazy(() => import("../component/customer/ViewCustomer"));
const LoginForm = React.lazy(() => import("../component/app/Login"));
const QuoteLayout = React.lazy(() => import("../component/quote"));
const QuoteList = React.lazy(() => import("../component/quote/QuoteList"));
const ViewQuote = React.lazy(() => import("../component/quote/ViewQuote"));
const CreateQuote = React.lazy(() => import("../component/quote/CreateQuote"));

const InvoiceLayout = React.lazy(() => import("../component/invoice"));
const InvoiceList = React.lazy(() => import("../component/invoice/InvoiceList"));
const CreateInvoice = React.lazy(() => import("../component/invoice/CreateInvoice"));
const ViewInvoice = React.lazy(() => import("../component/invoice/ViewInvoice"));
const PrintPreviewInvoice = React.lazy(() => import("../component/invoice/PrintPreviewInvoice"));

const PaymentLayout = React.lazy(() => import("../component/payment"));
const Payment = React.lazy(() => import("../component/payment/Payment"));
const DownloadPayment = React.lazy(() => import("../component/export/Payment"));

import { Layout, Spin } from "antd";

const LayoutLoader = () => (
  <Layout
    style={{
      padding: "24px 0 0",
      height: "calc(100% - 72px)",
      display: "flex",
      justifyContent: " center",
      alignItems: "center",
    }}
  >
    <Spin />
  </Layout>
);
const FullScreenLoader = () => (
  <div
    style={{
      height: "100vh",
      display: "flex",
      justifyContent: " center",
      alignItems: "center",
    }}
  >
    <Spin size="large" />
  </div>
);

const AppRouter = () => {
  return (
    <Routes>
      {/* general routing */}
      <Route
        path="/500"
        element={
          <Suspense fallback={<FullScreenLoader />}>
            <ServerError />
          </Suspense>
        }
      />
      <Route
        path="/404"
        element={
          <Suspense fallback={<FullScreenLoader />}>
            <NoMatch />
          </Suspense>
        }
      />
      <Route
        path="/403"
        element={
          <Suspense fallback={<FullScreenLoader />}>
            <UnAuthorized />
          </Suspense>
        }
      />
      {/* unauthorized routing */}
      <Route path="/" element={<UnauthorizedRoute />}>
        <Route
          index
          element={
            <Suspense fallback={<FullScreenLoader />}>
              <LoginForm />
            </Suspense>
          }
        />
        <Route
          path="login"
          element={
            <Suspense fallback={<FullScreenLoader />}>
              <LoginForm />
            </Suspense>
          }
        />
      </Route>
      {/* authorized routing */}
      <Route path="/" element={<RequireAuth />}>
        {/* Customer Page */}
        <Route
          path="customers"
          element={
            <Suspense fallback={<LayoutLoader />}>
              <CustomerLayout />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<LayoutLoader />}>
                <Customer />
              </Suspense>
            }
          />
          {/* <Route
            index
            path="create"
            element={
              <Suspense fallback={<LayoutLoader />}>
                <CreateCustomer />
              </Suspense>
            }
          /> */}
          <Route
            index
            path="view/:customer_id"
            element={
              <Suspense fallback={<LayoutLoader />}>
                <ViewCustomer />
              </Suspense>
            }
          />
        </Route>

        {/* Quote Page */}
        <Route
          path="quotes"
          element={
            <Suspense fallback={<LayoutLoader />}>
              <QuoteLayout />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<LayoutLoader />}>
                <QuoteList />
              </Suspense>
            }
          />
          <Route
            index
            path="create"
            element={
              <Suspense fallback={<LayoutLoader />}>
                <CreateQuote />
              </Suspense>
            }
          />
          <Route
            index
            path="view/:quote_id"
            element={
              <Suspense fallback={<LayoutLoader />}>
                <ViewQuote />
              </Suspense>
            }
          />
        </Route>

        {/* Invoice Page */}
        <Route
          path="invoices"
          element={
            <Suspense fallback={<LayoutLoader />}>
              <InvoiceLayout />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<LayoutLoader />}>
                <InvoiceList />
              </Suspense>
            }
          />
          <Route
            index
            path="create"
            element={
              <Suspense fallback={<LayoutLoader />}>
                <CreateInvoice />
              </Suspense>
            }
          />
          <Route
            index
            path="view/:invoice_id"
            element={
              <Suspense fallback={<LayoutLoader />}>
                <ViewInvoice />
              </Suspense>
            }
          />
          <Route
            index
            path="print/:invoice_id"
            element={
              <Suspense fallback={<LayoutLoader />}>
                <PrintPreviewInvoice />
              </Suspense>
            }
          />
        </Route>

        {/* Payment Page */}
        <Route
          path="payments"
          element={
            <Suspense fallback={<LayoutLoader />}>
              <PaymentLayout />
            </Suspense>
          }
        >
          <Route
            index
            element={
              <Suspense fallback={<LayoutLoader />}>
                <Payment />
              </Suspense>
            }
          />
          <Route
            index
            path="create"
            element={
              <Suspense fallback={<LayoutLoader />}>
                <CreateInvoice />
              </Suspense>
            }
          />
          <Route
            index
            path="download"
            element={
              <Suspense fallback={<LayoutLoader />}>
                <DownloadPayment />
              </Suspense>
            }
          />
        </Route>
      </Route>
      {/* wildcard */}
      <Route
        path="*"
        element={
          <Suspense fallback={<LayoutLoader />}>
            <NoMatch />
          </Suspense>
        }
      />
    </Routes>
  );
};

export default AppRouter;
