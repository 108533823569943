import React from "react";
import { Result } from "antd";

const NoMatch = ({ content }) => <Result status="404" title="404" subTitle={content} />;

NoMatch.defaultProps = {
  content: "Sorry, the page you visited does not exist.",
};
const ServerError = () => (
  <Result status="500" title="500" subTitle="Sorry, Something went wrong please try again later." />
);

const UnAuthorized = ({ content }) => (
  <Result
    status="403"
    title="403"
    subTitle={"Sorry, You are unauthorixed to visit this page. please contact administrator."}
  />
);

export { NoMatch, ServerError, UnAuthorized };
