import { put, takeLatest, all, call } from "redux-saga/effects";
import { quoteActions } from "./slice";
import { axiosClient } from "../../utils/helpers";

const getQuotesApi = (params) => {
  return axiosClient.get("quotes", { params });
};
const getQuoteByIdApi = (quote_id) => {
  return axiosClient.get(`quotes/${quote_id}`);
};
const addQuoteApi = (payload) => {
  return axiosClient.post("quotes", payload);
};
const getQuoteApi = (payload) => {
  return axiosClient.post("quotes/quote", payload);
};

const getCustomerQuotesApi = (quote_id) => {
  return axiosClient.get(`quotes/customers/${quote_id}`);
};
const updateQuoteApi = (payload) => {
  return axiosClient.put(`quotes/${payload?.quote_id}`, payload);
};
const updateQuoteStatusApi = (payload) => {
  return axiosClient.patch(`quotes/${payload?.quote_id}`, payload);
};
const deleteQuoteApi = (quoteId) => {
  return axiosClient.delete(`quotes/${quoteId}`);
};

function* getQuotes({ payload }) {
  try {
    const { data } = yield call(getQuotesApi, payload);
    yield put(quoteActions.getQuotesSuccess(data));
  } catch (error) {
    yield put(quoteActions.getQuotesFailure());
  }
}

function* getQuoteById({ payload }) {
  try {
    const { data } = yield call(getQuoteByIdApi, payload);
    yield put(quoteActions.getQuoteByIdSuccess(data));
  } catch (error) {
    yield put(quoteActions.getQuoteByIdFailure());
  }
}

function* getQuote({ payload }) {
  try {
    const { data } = yield call(getQuoteApi, payload);
    yield put(quoteActions.getQuoteSuccess(data));
  } catch (error) {
    yield put(quoteActions.getQuoteByIdFailure());
  }
}

function* addQuotes({ payload }) {
  try {
    const { data } = yield call(addQuoteApi, payload);
    yield put(quoteActions.addQuotesSuccess(data));
  } catch (error) {
    yield put(quoteActions.addQuotesFailure());
  }
}
function* updateQuotes({ payload }) {
  try {
    const { data } = yield call(updateQuoteApi, payload);
    yield put(quoteActions.addQuotesSuccess(data));
  } catch (error) {
    yield put(quoteActions.addQuotesFailure());
  }
}

function* updateQuoteStatus({ payload }) {
  try {
    const { data } = yield call(updateQuoteStatusApi, payload);
    yield put(quoteActions.addQuotesSuccess(data));
  } catch (error) {
    yield put(quoteActions.addQuotesFailure());
  }
}

function* getCustomerQuotes({ payload }) {
  try {
    const { data } = yield call(getCustomerQuotesApi, payload);
    yield put(quoteActions.getCustomerQuotesSuccess(data));
  } catch (error) {
    yield put(quoteActions.getCustomerQuotesFailure());
  }
}

function* deleteQuoteById({ payload }) {
  try {
    const { data } = yield call(deleteQuoteApi, payload);
    yield put(quoteActions.addQuotesSuccess(data));
  } catch (error) {
    yield put(quoteActions.addQuotesFailure());
  }
}

export function* quoteSaga() {
  yield all([
    takeLatest(quoteActions.getQuotes.toString(), getQuotes),
    takeLatest(quoteActions.getQuoteById.toString(), getQuoteById),
    takeLatest(quoteActions.deleteQuoteById.toString(), deleteQuoteById),
    takeLatest(quoteActions.addQuotes.toString(), addQuotes),
    takeLatest(quoteActions.updateQuotes.toString(), updateQuotes),
    takeLatest(quoteActions.updateQuoteStatus.toString(), updateQuoteStatus),
    takeLatest(quoteActions.getCustomerQuotes.toString(), getCustomerQuotes),
    takeLatest(quoteActions.getQuote.toString(), getQuote),
  ]);
}
