// actions in slice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companyData: {},
  loading: false,
  error: null,
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    addCompany: (state, action) => {
      state.loading = true;
    },
    addCompanySuccess: (state, action) => {
      state.loading = false;
    },
    addCompanyFailure: (state) => {
      state.loading = false;
      state.error = "Failed to add company";
    },
    getCompanyById: (state) => {
      state.loading = true;
    },
    getCompanyByIdSuccess: (state, action) => {
      state.companyData = action.payload?.data || {};
      state.loading = false;
    },
    getCompanyByIdFailure: (state) => {
      state.loading = false;
      state.error = "Failed to load company";
    },
  },
});

export const companyActions = companySlice.actions;
export default companySlice.reducer;
