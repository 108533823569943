import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import App from "./App";
import ErrorBoundary from "./component/app/ErrorBoundary";

import store from "./redux/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter basename={"/"}>
        <App />
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>
);
