import dayjs from "dayjs";
import { Layout } from "antd";
import React, { useEffect } from "react";
import customParseFormat from "dayjs/plugin/customParseFormat";

import AppRouter from "./routes";
import { ConfigProvider, theme } from "antd";

import "./App.scss";
import { useAuth } from "utils/hooks";

dayjs.extend(customParseFormat);

const { compactAlgorithm } = theme;

const App = () => {
  const isAuthenticated = useAuth("isAuthenticated");

  useEffect(() => {
    if (isAuthenticated) {
      processAdLogin();
    }
  }, [isAuthenticated]);

  const processAdLogin = () => {
    if (process.env.REACT_APP_ENV == "dev") return;
    // updateClientToken(response.idToken);
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: [compactAlgorithm],
      }}
    >
      <Layout
        style={{
          minHeight: "100vh",
        }}
        className="os-web-main"
      >
        <AppRouter />
      </Layout>
    </ConfigProvider>
  );
};
export default App;
