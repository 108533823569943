import { put, takeLatest, all, call } from "redux-saga/effects";
import { customerActions } from "./slice";
import { axiosClient } from "../../utils/helpers";

const addCustomerApi = (payload) => {
  return axiosClient.post("customers", payload);
};

const updateCustomerApi = (payload) => {
  return axiosClient.put(`customers/${payload?.customer_id}`, payload);
};

const getCustomersApi = (params) => {
  return axiosClient.get("customers", { params });
};

const getCustomerByIdApi = (customer_id) => {
  return axiosClient.get(`customers/${customer_id}`);
};

const getCustomerAddressApi = (customer_id) => {
  return axiosClient.get(`customers/${customer_id}/addresses`);
};

const addCustomerAddressApi = (payload) => {
  return axiosClient.post(`customers/${payload.customer_id}/addresses`, payload);
};

const updateCustomerAddressApi = (payload) => {
  return axiosClient.put(`customers/${payload.customer_id}/addresses/${payload.address_id}`, payload);
};

function* getCustomers({ payload }) {
  try {
    const { data } = yield call(getCustomersApi, payload);
    yield put(customerActions.getCustomersSuccess(data));
  } catch (error) {
    yield put(customerActions.getCustomersFailure());
  }
}

function* getCustomerById({ payload }) {
  try {
    const { data } = yield call(getCustomerByIdApi, payload);
    yield put(customerActions.getCustomerByIdSuccess(data));
  } catch (error) {
    yield put(customerActions.getCustomerByIdFailure());
  }
}

function* getCustomerAddress({ payload }) {
  try {
    const { data } = yield call(getCustomerAddressApi, payload);
    yield put(customerActions.getCustomerAddressSuccess(data));
  } catch (error) {
    yield put(customerActions.getCustomerAddressFailure());
  }
}

function* addCustomers({ payload }) {
  try {
    const { data } = yield call(addCustomerApi, payload);
    yield put(customerActions.addCustomersSuccess(data));
  } catch (error) {
    yield put(customerActions.addCustomersFailure());
  }
}

function* updateCustomers({ payload }) {
  try {
    const { data } = yield call(updateCustomerApi, payload);
    yield put(customerActions.addCustomersSuccess(data));
  } catch (error) {
    yield put(customerActions.addCustomersFailure());
  }
}

function* addCustomerAddress({ payload }) {
  try {
    const { data } = yield call(addCustomerAddressApi, payload);
    yield put(customerActions.addCustomersSuccess(data));
  } catch (error) {
    yield put(customerActions.addCustomersFailure());
  }
}

function* updateCustomerAddress({ payload }) {
  try {
    const { data } = yield call(updateCustomerAddressApi, payload);
    yield put(customerActions.addCustomersSuccess(data));
  } catch (error) {
    yield put(customerActions.addCustomersFailure());
  }
}

export function* customerSaga() {
  yield all([
    takeLatest(customerActions.getCustomers.toString(), getCustomers),
    takeLatest(customerActions.addCustomers.toString(), addCustomers),
    takeLatest(customerActions.updateCustomers.toString(), updateCustomers),
    takeLatest(customerActions.getCustomerById.toString(), getCustomerById),
    takeLatest(customerActions.getCustomerAddress.toString(), getCustomerAddress),
    takeLatest(customerActions.addCustomerAddress.toString(), addCustomerAddress),
    takeLatest(customerActions.updateCustomerAddress.toString(), updateCustomerAddress),
  ]);
}
