import React from "react";
import { Outlet, useLocation, Navigate, matchPath, useNavigate } from "react-router-dom";
import Icon, { AuditOutlined, BankOutlined, InboxOutlined, ProjectOutlined, TeamOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { Avatar, Layout, Popover } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useAuth } from "../../utils/hooks";
import { logOut } from "../../redux/auth/slice";
import Logo from "../../asset/img/logo-white.png";
import { Link } from "react-router-dom";

const { Header } = Layout;

const NavBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useAuth("user");
  const handleLogout = () => {
    dispatch(logOut());
  };

  const handleChange = (data) => {
    navigate(`/${data.target.value}`);
  };

  return (
    <Header
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background: "#005061",
        padding: "0 16px 0 0",
        height: 60,
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 999,
      }}
    >
      <div
        style={{
          background: "#00BACB",
          paddingLeft: 16,
          paddingRight: 16,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <img className="main-logo" src={Logo} alt="logo" />
      </div>
      <div className="main-menu">
        {/* <div className="main-menu-item">Dashboard</div> */}
        <div className="main-menu-item">
          <Link to="/customers">
            {" "}
            <TeamOutlined /> Customers
          </Link>
        </div>
        <div className="main-menu-item">
          <Link to="/quotes">
            {" "}
            <AuditOutlined /> Quotes
          </Link>
        </div>
        <div className="main-menu-item">
          <Link to="/invoices">
            {" "}
            <InboxOutlined /> Invoices
          </Link>
        </div>
        <div className="main-menu-item">
          <Link to="/payments">
            {" "}
            <BankOutlined /> Payments
          </Link>
        </div>
      </div>
      <div
        onClick={() => {
          handleLogout();
        }}
        style={{ cursor: "pointer", color: "#ffffff", fontWeight: 500, display: "flex", fontSize: 16 }}
      >
        {user?.first_name} {user?.last_name} &nbsp;&nbsp; <LogoutOutlined style={{ fontSize: 24 }} />
      </div>
    </Header>
  );
};

export default NavBar;
