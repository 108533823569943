import { put, takeLatest, all, call } from "redux-saga/effects";
import { paymentActions } from "./slice";
import { axiosClient } from "../../utils/helpers";

const addPaymentApi = (payload) => {
  return axiosClient.post("payments", payload);
};

const getPaymentsApi = (params) => {
  return axiosClient.get("payments", { params });
  E;
};

const getPaymentByIdApi = (payment_id) => {
  return axiosClient.get(`payments/${payment_id}`);
};

function* getPayments({ payload }) {
  try {
    const { data } = yield call(getPaymentsApi, payload);
    yield put(paymentActions.getPaymentsSuccess(data));
  } catch (error) {
    yield put(paymentActions.getPaymentsFailure());
  }
}

function* getPaymentById({ payload }) {
  try {
    const { data } = yield call(getPaymentByIdApi, payload);
    yield put(paymentActions.getPaymentByIdSuccess(data));
  } catch (error) {
    yield put(paymentActions.getPaymentByIdFailure());
  }
}

function* addPayments({ payload }) {
  try {
    const { data } = yield call(addPaymentApi, payload);
    yield put(paymentActions.addPaymentsSuccess(data));
  } catch (error) {
    yield put(paymentActions.addPaymentsFailure());
  }
}

export function* paymentSaga() {
  yield all([
    takeLatest(paymentActions.getPayments.toString(), getPayments),
    takeLatest(paymentActions.addPayments.toString(), addPayments),
    takeLatest(paymentActions.getPaymentById.toString(), getPaymentById),
  ]);
}
