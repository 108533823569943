import axios from "axios";
import { message } from "antd";
import { redirect } from "react-router-dom";

import store from "../../redux/store";
import { checkAuthorizationFailure } from "../../redux/auth/slice";
import { clear } from "@testing-library/user-event/dist/clear";

const client = axios.create({
  // eslint-disable-next-line no-undef
  baseURL: process.env.REACT_APP_BASE_URL,
  ...(localStorage.getItem("token")
    ? {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    : {}),
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      redirectToLogin();
    }
    if (error.response && error.response.status === 500) {
      window.location.href = "/500";
    }
    if (error.response && error.response.status === 403) {
      const errRes = error?.response?.data?.errors[0];
      if (errRes?.is_token_expired) {
        redirectToLogin();
      } else {
        window.location.href = "/403";
      }
    }
    return Promise.reject(error);
  }
);

const redirectToLogin = () => {
  message.error("Unauthorized to access it");
  store.dispatch(checkAuthorizationFailure());
  localStorage.clear();
  sessionStorage.clear();
  deleteAllCookies();
  window.location.href = "/login";
};

const deleteAllCookies = () => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
  }
};

export const updateClientToken = (data) => {
  localStorage.setItem("token", data);
  client.defaults.headers.Authorization = `Bearer ${data}`;
};

export default client;
