import { createSlice } from "@reduxjs/toolkit";

const commonInitialState = {
  locations: [],
  area: [],
  roles: [],
};

export const commonSlice = createSlice({
  name: "common",
  initialState: commonInitialState,
  reducers: {
    getLocations: () => {},
    getLocationsSuccess: (state, { payload }) => {
      state.locations = payload;
    },
    getLocationsFailure: (state) => {
      state.locations = [];
    },
    getArea: () => {},
    getAreaSuccess: (state, { payload }) => {
      state.area = payload;
    },
    getAreaFailure: (state) => {
      state.area = [];
    },
    getRoles: () => {},
    getRolesSuccess: (state, { payload }) => {
      state.roles = payload;
    },
    getRolesFailure: (state) => {
      state.roles = [];
    },
  },
});

export const { actions: commonActions } = commonSlice;

export default commonSlice.reducer;
