// actions in slice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  quoteData: {},
  quotes: [],
  loading: false,
  navigateToQuotes: false,
  submitInProgress: false,
  error: null,
  existingQuote: {},
};

const quoteSlice = createSlice({
  name: "quote",
  initialState,
  reducers: {
    getQuotes: (state) => {
      state.loading = true;
    },
    getQuotesSuccess: (state, action) => {
      state.quotes = action.payload?.data;
      state.loading = false;
    },
    getQuotesFailure: (state) => {
      state.loading = false;
      state.error = "Failed to load quotes";
    },
    getQuoteById: (state) => {
      state.loading = true;
    },
    getQuoteByIdSuccess: (state, action) => {
      state.quoteData = action.payload?.data || {};
      state.loading = false;
    },
    getQuoteByIdFailure: (state) => {
      state.loading = false;
      state.error = "Failed to load quotes";
    },
    addQuotes: (state, action) => {
      state.loading = true;
      state.navigateToQuotes = false;
      state.submitInProgress = true;
    },
    updateQuotes: (state, action) => {
      state.loading = true;
      state.navigateToQuotes = false;
      state.submitInProgress = true;
    },
    addQuotesSuccess: (state, action) => {
      state.loading = false;
      state.navigateToQuotes = true;
    },
    addQuotesFailure: (state) => {
      state.loading = false;
      state.error = "Failed to add quotes";
    },
    getCustomerQuotes: (state) => {
      state.loading = true;
    },
    getCustomerQuotesSuccess: (state, action) => {
      state.quotes = action.payload?.data;
      state.loading = false;
    },
    getCustomerQuotesFailure: (state) => {
      state.loading = false;
      state.error = "Failed to load quotes";
    },
    deleteQuoteById: (state) => {
      state.loading = true;
    },
    updateQuoteStatus: (state, action) => {
      state.loading = true;
    },
    getQuote: (state, action) => {
      state.loading = true;
      state.existingQuote = {};
    },
    getQuoteSuccess: (state, action) => {
      state.existingQuote = action.payload?.data || {};
    },
  },
});

export const quoteActions = quoteSlice.actions;
export default quoteSlice.reducer;
