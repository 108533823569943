// actions in slice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  customerData: {},
  customerAddressData: [],
  customers: [],
  loading: false,
  submitInProgress: false,
  navigateToCustomers: false,
  error: null,
};

const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    getCustomers: (state) => {
      state.loading = true;
      state.navigateToCustomers = false;
    },
    getCustomersSuccess: (state, action) => {
      state.customers = action.payload?.data;
      state.loading = false;
    },
    getCustomersFailure: (state) => {
      state.loading = false;
      state.error = "Failed to load customers";
    },
    addCustomers: (state, action) => {
      state.loading = true;
      state.submitInProgress = true;
      state.navigateToCustomers = false;
    },
    updateCustomers: (state, action) => {
      state.loading = true;
      state.submitInProgress = true;
      state.navigateToCustomers = false;
    },
    addCustomersSuccess: (state, action) => {
      state.navigateToCustomers = true;
      state.submitInProgress = false;
      state.loading = false;
    },
    addCustomersFailure: (state) => {
      state.loading = false;
      state.submitInProgress = false;
      state.error = "Failed to add customer";
    },
    getCustomerById: (state) => {
      state.loading = true;
    },
    getCustomerByIdSuccess: (state, action) => {
      state.customerData = action.payload?.data || {};
      state.loading = false;
    },
    getCustomerByIdFailure: (state) => {
      state.loading = false;
      state.error = "Failed to load customer";
    },
    getCustomerAddress: (state) => {
      state.loading = true;
    },
    getCustomerAddressSuccess: (state, action) => {
      state.customerAddressData = action.payload?.data || [];
      state.loading = false;
    },
    getCustomerAddressFailure: (state) => {
      state.loading = false;
      state.error = "Failed to load customer";
    },
    addCustomerAddress: (state) => {
      state.loading = true;
      state.submitInProgress = true;
      state.navigateToCustomers = false;
    },
    updateCustomerAddress: (state) => {
      state.loading = true;
      state.submitInProgress = true;
      state.navigateToCustomers = false;
    },
  },
});

export const customerActions = customerSlice.actions;
export default customerSlice.reducer;
