import React from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";

import { useAuth } from "../utils/hooks";

const UnauthorizedRoute = () => {
  let location = useLocation();
  const isAuthenticated = useAuth("isAuthenticated");

  if (!isAuthenticated) {
    return <Outlet />;
  }

  return <Navigate to={location?.state?.from || "/customers"} replace />;
};

export default UnauthorizedRoute;
