// actions in slice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  invoiceData: {},
  invoices: [],
  loading: false,
  navigateToInvoices: false,
  submitInProgress: false,
  error: null,
  existingInvoice: {},
};

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    getInvoices: (state) => {
      state.loading = true;
    },
    getInvoicesSuccess: (state, action) => {
      state.invoices = action.payload?.data;
      state.loading = false;
    },
    getInvoicesFailure: (state) => {
      state.loading = false;
      state.error = "Failed to load invoices";
    },
    getInvoiceById: (state) => {
      state.loading = true;
    },
    getInvoiceByIdSuccess: (state, action) => {
      state.invoiceData = action.payload?.data || {};
      state.loading = false;
    },
    getInvoiceByIdFailure: (state) => {
      state.loading = false;
      state.error = "Failed to load invoices";
    },
    addInvoice: (state, action) => {
      state.loading = true;
      state.navigateToInvoices = false;
      state.submitInProgress = true;
    },
    updateInvoice: (state, action) => {
      state.loading = true;
      state.navigateToInvoices = false;
      state.submitInProgress = true;
    },
    addInvoiceSuccess: (state, action) => {
      state.loading = false;
      state.navigateToInvoices = true;
      state.submitInProgress = false;
    },
    addInvoiceFailure: (state) => {
      state.loading = false;
      state.submitInProgress = false;
      state.error = "Failed to add invoices";
    },
    getCustomerInvoices: (state) => {
      state.loading = true;
    },
    getCustomerInvoicesSuccess: (state, action) => {
      state.invoices = action.payload?.data;
      state.loading = false;
    },
    getCustomerInvoicesFailure: (state) => {
      state.loading = false;
      state.error = "Failed to load invoices";
    },
    getInvoice: (state, action) => {
      state.loading = true;
      state.existingInvoice = {};
    },
    getInvoiceSuccess: (state, action) => {
      state.existingInvoice = action.payload?.data || {};
    },
    deleteInvoiceById: (state) => {
      state.loading = true;
    },
    updateInvoiceStatus: (state, action) => {
      state.loading = true;
    },
  },
});

export const invoiceActions = invoiceSlice.actions;
export default invoiceSlice.reducer;
