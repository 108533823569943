import { put, takeLatest, all, call } from "redux-saga/effects";
import { companyActions } from "./slice";
import { axiosClient } from "../../utils/helpers";

const addCompanyApi = (payload) => {
  return axiosClient.post("companies", payload);
};

const getCompanyByIdApi = (company_id) => {
  return axiosClient.get(`companies/${company_id}`);
};

function* getCompanyById({ payload }) {
  try {
    const { data } = yield call(getCompanyByIdApi, payload);
    yield put(companyActions.getCompanyByIdSuccess(data));
  } catch (error) {
    yield put(companyActions.getCompanyByIdFailure());
  }
}

function* addCompany({ payload }) {
  try {
    const { data } = yield call(addCompanyApi, payload);
    yield put(companyActions.addCompanySuccess(data));
  } catch (error) {
    yield put(companyActions.addCompanyFailure());
  }
}

export function* companySaga() {
  yield all([
    takeLatest(companyActions.addCompany.toString(), addCompany),
    takeLatest(companyActions.getCompanyById.toString(), getCompanyById),
  ]);
}
