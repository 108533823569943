import React from "react";
import { Layout, Radio } from "antd";
import { Outlet, useLocation, Navigate, matchPath, useNavigate } from "react-router-dom";

import { useAuth } from "../utils/hooks";
import NavBar from "../component/app/NavBar";

const { Content } = Layout;

const RequireAuth = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    params: { mainPath },
  } = matchPath({ path: "/:mainPath/*" }, location.pathname);

  // const isAuthenticated = useAuth("isAuthenticated");
  const isAuthenticated = useAuth("isAuthenticated");

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  const handleChange = (data) => {
    navigate(`/${data.target.value}`);
  };

  return (
    <>
      <NavBar />
      <Content
        style={{
          padding: "0 24px 24px",
          flexGrow: 1,
          marginTop: 60,
        }}
      >
        <Outlet />
      </Content>
    </>
  );
};

export default RequireAuth;
