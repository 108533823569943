import { createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";

const authInitialState = {
  loader: true,
  isAuthenticated: !!localStorage.getItem("token"),
  user: localStorage.getItem("token") ? jwtDecode(localStorage.getItem("token")) : {},
};

export const authSlice = createSlice({
  name: "auth",
  initialState: authInitialState,
  reducers: {
    checkAuthorization: (state) => {
      state.loader = true;
    },
    checkAuthorizationSuccess: (state, { payload }) => {
      state.loader = false;
      state.isAuthenticated = !!payload;
      state.user = jwtDecode(payload);
    },
    checkAuthorizationFailure: (state) => {
      state.loader = false;
      state.isAuthenticated = false;
    },
    logOut: (state) => {
      localStorage.clear();
      sessionStorage.clear();
      state.isAuthenticated = false;
    },
  },
});

export const { checkAuthorization, checkAuthorizationSuccess, checkAuthorizationFailure, logOut } = authSlice.actions;
export default authSlice.reducer;
