import { put, takeLatest, all } from "redux-saga/effects";

import { axiosClient } from "../../utils/helpers";
import { commonActions } from "./slice";

function* getLocations() {
  try {
    const { data } = yield axiosClient.get(`locations`);
    yield put(commonActions.getLocationsSuccess(data.result));
  } catch (error) {
    yield put(commonActions.getLocationsFailure());
  }
}
function* getArea({ payload }) {
  try {
    const { data } = yield axiosClient.get(`area${payload ? `/${payload}` : ""}`);
    yield put(commonActions.getAreaSuccess(data.result));
  } catch (error) {
    yield put(commonActions.getAreaFailure());
  }
}

function* getRoles() {
  try {
    const { data } = yield axiosClient.get(`roles`);
    yield put(commonActions.getRolesSuccess(data.result));
  } catch (error) {
    yield put(commonActions.getRolesFailure());
  }
}

export function* commonSaga() {
  yield all([
    takeLatest(commonActions.getArea.toString(), getArea),
    takeLatest(commonActions.getRoles.toString(), getRoles),
    takeLatest(commonActions.getLocations.toString(), getLocations),
  ]);
}
