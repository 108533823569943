import { put, takeLatest, all, call } from "redux-saga/effects";
import { invoiceActions } from "./slice";
import { axiosClient } from "../../utils/helpers";

const getInvoicesApi = (params) => {
  return axiosClient.get("invoices", { params });
};
const getInvoiceByIdApi = (invoice_id) => {
  return axiosClient.get(`invoices/${invoice_id}`);
};
const addInvoiceApi = (payload) => {
  return axiosClient.post("invoices", payload);
};
const getCustomerInvoicesApi = (customer_id) => {
  return axiosClient.get(`invoices/customers/${customer_id}`);
};
const updateInvoiceApi = (payload) => {
  return axiosClient.put(`invoices/${payload?.invoice_id}`, payload);
};
const getInvoiceApi = (payload) => {
  return axiosClient.post("invoices/invoice", payload);
};
const deleteInvoiceApi = (invoiceId) => {
  return axiosClient.delete(`invoices/${invoiceId}`);
};
const updateInvoiceStatusApi = (payload) => {
  return axiosClient.patch(`invoices/${payload?.invoice_id}`, payload);
};

function* getInvoices({ payload }) {
  try {
    const { data } = yield call(getInvoicesApi, payload);
    yield put(invoiceActions.getInvoicesSuccess(data));
  } catch (error) {
    yield put(invoiceActions.getInvoicesFailure());
  }
}

function* getInvoiceById({ payload }) {
  try {
    const { data } = yield call(getInvoiceByIdApi, payload);
    yield put(invoiceActions.getInvoiceByIdSuccess(data));
  } catch (error) {
    yield put(invoiceActions.getInvoiceByIdFailure());
  }
}

function* addInvoice({ payload }) {
  try {
    const { data } = yield call(addInvoiceApi, payload);
    yield put(invoiceActions.addInvoiceSuccess(data));
  } catch (error) {
    yield put(invoiceActions.addInvoiceFailure());
  }
}

function* updateInvoice({ payload }) {
  try {
    const { data } = yield call(updateInvoiceApi, payload);
    yield put(invoiceActions.addInvoiceSuccess(data));
  } catch (error) {
    yield put(invoiceActions.addInvoiceFailure());
  }
}

function* getCustomerInvoices({ payload }) {
  try {
    const { data } = yield call(getCustomerInvoicesApi, payload);
    yield put(invoiceActions.getCustomerInvoicesSuccess(data));
  } catch (error) {
    yield put(invoiceActions.getCustomerInvoicesFailure());
  }
}

function* getInvoice({ payload }) {
  try {
    const { data } = yield call(getInvoiceApi, payload);
    yield put(invoiceActions.getInvoiceSuccess(data));
  } catch (error) {
    yield put(invoiceActions.getInvoiceByIdFailure());
  }
}

function* deleteInvoiceById({ payload }) {
  try {
    const { data } = yield call(deleteInvoiceApi, payload);
    yield put(invoiceActions.addInvoiceSuccess(data));
  } catch (error) {
    yield put(invoiceActions.addInvoiceFailure());
  }
}

function* updateInvoiceStatus({ payload }) {
  try {
    const { data } = yield call(updateInvoiceStatusApi, payload);
    yield put(invoiceActions.addInvoiceSuccess(data));
  } catch (error) {
    yield put(invoiceActions.addInvoiceFailure());
  }
}

export function* invoiceSaga() {
  yield all([
    takeLatest(invoiceActions.getInvoices.toString(), getInvoices),
    takeLatest(invoiceActions.getInvoiceById.toString(), getInvoiceById),
    takeLatest(invoiceActions.addInvoice.toString(), addInvoice),
    takeLatest(invoiceActions.updateInvoice.toString(), updateInvoice),
    takeLatest(invoiceActions.getCustomerInvoices.toString(), getCustomerInvoices),
    takeLatest(invoiceActions.getInvoice.toString(), getInvoice),
    takeLatest(invoiceActions.deleteInvoiceById.toString(), deleteInvoiceById),
    takeLatest(invoiceActions.updateInvoiceStatus.toString(), updateInvoiceStatus),
  ]);
}
