// actions in slice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  paymentData: {},
  payments: [],
  loading: false,
  navigateToPayments: false,
  error: null,
};

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    getPayments: (state) => {
      state.loading = true;
      state.navigateToPayments = false;
    },
    getPaymentsSuccess: (state, action) => {
      state.payments = action.payload?.data;
      state.loading = false;
    },
    getPaymentsFailure: (state) => {
      state.loading = false;
      state.error = "Failed to load payments";
    },
    addPayments: (state, action) => {
      state.loading = true;
      state.navigateToPayments = false;
    },
    addPaymentsSuccess: (state, action) => {
      state.navigateToPayments = true;
      state.loading = false;
    },
    addPaymentsFailure: (state) => {
      state.loading = false;
      state.error = "Failed to add payment";
    },
    getPaymentById: (state) => {
      state.loading = true;
    },
    getPaymentByIdSuccess: (state, action) => {
      state.paymentData = action.payload?.data || {};
      state.loading = false;
    },
    getPaymentByIdFailure: (state) => {
      state.loading = false;
      state.error = "Failed to load payment";
    },
  },
});

export const paymentActions = paymentSlice.actions;
export default paymentSlice.reducer;
